import { call, put, takeLatest, } from 'redux-saga/effects'

import {
  CREATE_ANIMAL,
  CREATE_ANIMAL_FAILED,
  CREATE_ANIMAL_REQUEST,
  CREATE_ANIMAL_SUCCESS,
  CREATE_EVENT,
  CREATE_EVENT_FAILED,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_SCHEDULE,
  CREATE_SCHEDULE_FAILED,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  DELETE_ANIMAL,
  DELETE_ANIMAL_FAILED,
  DELETE_ANIMAL_REQUEST,
  DELETE_ANIMAL_SUCCESS,
  DELETE_EVENT,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_SCHEDULE,
  DELETE_SCHEDULE_FAILED,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  GET_ALL_ANIMALS,
  GET_ALL_ANIMALS_FAILED,
  GET_ALL_ANIMALS_REQUEST,
  GET_ALL_ANIMALS_SUCCESS,
  GET_ANIMAL,
  GET_ANIMAL_FAILED,
  GET_ANIMAL_REQUEST,
  GET_ANIMAL_SUCCESS,
  GET_EVENT,
  GET_EVENT_FAILED,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_SCHEDULE,
  GET_SCHEDULE_FAILED,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  UPDATE_ANIMAL,
  UPDATE_ANIMAL_FAILED,
  UPDATE_ANIMAL_REQUEST,
  UPDATE_ANIMAL_SUCCESS,
  UPDATE_EVENT,
  UPDATE_EVENT_FAILED,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_SCHEDULE,
  UPDATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_MOULT,
  UPDATE_MOULT_FAILED,
  UPDATE_MOULT_REQUEST,
  UPDATE_MOULT_SUCCESS,
} from './constants'

import {
  createAnimal,
  createEvent,
  deleteAnimal,
  deleteEvent,
  requestAllAnimals,
  requestAnimal,
  requestEvent,
  updateAnimal,
  updateEvent,
  updateMoult,
  requestSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule,
} from './actions'

export default function * animalWatcher () {
  yield takeLatest([ GET_ALL_ANIMALS, ], getAllAnimalsSaga)
  yield takeLatest([ GET_ANIMAL, ], getAnimalSaga)
  yield takeLatest([ CREATE_ANIMAL, ], createAnimalSaga)
  yield takeLatest([ UPDATE_ANIMAL, ], updateAnimalSaga)
  yield takeLatest([ DELETE_ANIMAL, ], deleteAnimalSaga)
  yield takeLatest([ GET_EVENT, ], getEventSaga)
  yield takeLatest([ CREATE_EVENT, ], createEventSaga)
  yield takeLatest([ UPDATE_EVENT, ], updateEventSaga)
  yield takeLatest([ DELETE_EVENT, ], deleteEventSaga)
  yield takeLatest([ GET_SCHEDULE, ], getScheduleSaga)
  yield takeLatest([ CREATE_SCHEDULE, ], createScheduleSaga)
  yield takeLatest([ UPDATE_SCHEDULE, ], updateScheduleSaga)
  yield takeLatest([ DELETE_SCHEDULE, ], deleteScheduleSaga)
  return yield takeLatest([ UPDATE_MOULT, ], updateMoultSaga)
}

function * getAllAnimalsSaga () {
  yield put({ type: GET_ALL_ANIMALS_REQUEST, })
  const data = yield call(requestAllAnimals)
  if (!data) {
    return yield put({
      type: GET_ALL_ANIMALS_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: GET_ALL_ANIMALS_SUCCESS,
    payload: data,
  })
}

function * getAnimalSaga ({ payload, }) {
  yield put({ type: GET_ANIMAL_REQUEST, })
  const data = yield call(requestAnimal, payload)
  if (!data) {
    return yield put({
      type: GET_ANIMAL_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: GET_ANIMAL_SUCCESS,
    payload: data,
  })
}

function * createAnimalSaga ({ payload, }) {
  yield put({ type: CREATE_ANIMAL_REQUEST, })
  const data = yield call(createAnimal, payload)
  if (!data) {
    return yield put({
      type: CREATE_ANIMAL_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: CREATE_ANIMAL_SUCCESS,
    payload: data,
  })
}

function * updateAnimalSaga ({ payload, }) {
  yield put({ type: UPDATE_ANIMAL_REQUEST, })
  const data = yield call(updateAnimal, payload)
  if (!data) {
    return yield put({
      type: UPDATE_ANIMAL_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: UPDATE_ANIMAL_SUCCESS,
    payload,
  })
}

function * deleteAnimalSaga ({ payload, }) {
  yield put({ type: DELETE_ANIMAL_REQUEST, })
  const data = yield call(deleteAnimal, payload)
  if (!data) {
    return yield put({
      type: DELETE_ANIMAL_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: DELETE_ANIMAL_SUCCESS,
    payload,
  })
}

// event
function * getEventSaga ({ payload, }) {
  yield put({ type: GET_EVENT_REQUEST, })
  const data = yield call(requestEvent, payload)
  if (!data) {
    return yield put({
      type: GET_EVENT_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: GET_EVENT_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * createEventSaga ({ payload, }) {
  yield put({ type: CREATE_EVENT_REQUEST, })
  const data = yield call(createEvent, payload)
  if (!data) {
    return yield put({
      type: CREATE_EVENT_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: CREATE_EVENT_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * updateEventSaga ({ payload, }) {
  yield put({ type: UPDATE_EVENT_REQUEST, })
  const data = yield call(updateEvent, payload)
  if (!data) {
    return yield put({
      type: UPDATE_EVENT_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: UPDATE_EVENT_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * deleteEventSaga ({ payload, }) {
  yield put({ type: DELETE_EVENT_REQUEST, })
  const data = yield call(deleteEvent, payload)
  if (!data) {
    return yield put({
      type: DELETE_EVENT_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: DELETE_EVENT_SUCCESS,
    payload: { id: payload.id, },
  })
}

// update moult status
function * updateMoultSaga ({ payload, }) {
  yield put({ type: UPDATE_MOULT_REQUEST, })
  const data = yield call(updateMoult, payload)
  if (!data) {
    return yield put({
      type: UPDATE_MOULT_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: UPDATE_MOULT_SUCCESS,
    payload,
  })
}

// schedule
function * getScheduleSaga ({ payload, }) {
  yield put({ type: GET_SCHEDULE_REQUEST, })
  const data = yield call(requestSchedule, payload)
  if (!data) {
    return yield put({
      type: GET_SCHEDULE_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: GET_SCHEDULE_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * createScheduleSaga ({ payload, }) {
  yield put({ type: CREATE_SCHEDULE_REQUEST, })
  const data = yield call(createSchedule, payload)
  if (!data) {
    return yield put({
      type: CREATE_SCHEDULE_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: CREATE_SCHEDULE_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * updateScheduleSaga ({ payload, }) {
  yield put({ type: UPDATE_SCHEDULE_REQUEST, })
  const data = yield call(updateSchedule, payload)
  if (!data) {
    return yield put({
      type: UPDATE_SCHEDULE_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: UPDATE_SCHEDULE_SUCCESS,
    payload: { id: payload.AnimalId, data, },
  })
}

function * deleteScheduleSaga ({ payload, }) {
  yield put({ type: DELETE_SCHEDULE_REQUEST, })
  const data = yield call(deleteSchedule, payload)
  if (!data) {
    return yield put({
      type: DELETE_SCHEDULE_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: DELETE_SCHEDULE_SUCCESS,
    payload: { id: payload.id, },
  })
}
