import React, { useState, } from 'react'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  Input,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { fr, } from 'date-fns/locale'
import {
  CREATE_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
} from '../../../store/animal/constants'
import { useDispatch, useSelector, } from 'react-redux'
import { useParams, } from '@reach/router'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    padding: 5,
  },
  input: {
    marginBottom: 10,
  },
  multilineColor: {
    background: theme.palette.background.default,
    color: theme.palette.common.white,
  },
  buttonGroup: {
    alignSelf: 'center',
    marginBottom: 10,
  },
  popper: {
    zIndex: 90,
  },
  cancelButton: {
    marginRight: 160,
    marginLeft: 160,
  },
}))

const options = [ 'save', 'delete', ]

export default function EventForm (props) {
  const { t, } = useTranslation()
  const classes = styles()

  const { handleCancel, animalType, animalStockId, animalStockConsumed, } = props
  const event = props.event || { data: {}, }
  const { stock, } = useSelector(state => state.stock)
  const dispatch = useDispatch()
  const params = useParams()

  const [ text, setText, ] = useState(event.text || '')
  const [ dNbStockConsumed, setNbStockConsumed, ] = useState(
    (animalType === 'SNAKE' && event.data.nbStockConsumed) || animalStockConsumed
  )
  const [ dWeigh, setDWeigh, ] = useState(event.data.weigh || null)
  const [ dNew, setDNew, ] = useState(event.data.new || null)
  const [ dOld, setDOld, ] = useState(event.data.old || null)
  const [ dInfo, setDInfo, ] = useState(event.data.info || '')
  const [ type, setType, ] = useState(event.type || 'MEAL')
  const [ stockId, setStockId, ] = useState(animalStockId || 0)
  const [ startDuration, setStartDuration, ] = useState(
    event.startDuration || new Date()
  )
  const [ finishDuration, setFinishDuration, ] = useState(
    event.finishDuration || new Date()
  )

  const [ open, setOpen, ] = React.useState(false)
  const anchorRef = React.useRef(null)
  const [ selectedIndex, setSelectedIndex, ] = React.useState(0)

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleSave = () => {
    const payload = {
      text,
      data: {
        weigh: dWeigh,
        new: dNew,
        old: dOld,
        info: dInfo,
        nbStockConsumed: dNbStockConsumed,
      },
      type,
      startDuration,
      finishDuration: [ 'MEDICAL', 'INCIDENT', ].includes(type)
        ? finishDuration
        : null,
      AnimalId: params.id,
      StockId: stockId,
    }
    if (event.id) {
      dispatch({
        type: UPDATE_EVENT,
        payload: {
          ...payload,
          id: event.id,
        },
      })
      handleCancel()
    } else {
      dispatch({
        type: CREATE_EVENT,
        payload: payload,
      })
      setDWeigh(null)
      setDOld(null)
      setDNew(null)
      setDInfo('')
      setText('')
      setType('MEAL')
      setStartDuration(new Date())
      setFinishDuration(new Date())
    }
  }

  const handleDelete = () => {
    dispatch({
      type: DELETE_EVENT,
      payload: { id: event.id, },
    })
  }

  return (
    <Paper className={classes.root}>
      <Select
        className={classes.input}
        labelId="selectTypeLabel"
        id="selectType"
        value={type}
        onChange={e => setType(e.target.value)}
        defaultValue={'MEAL'}
      >
        <MenuItem value={'MEAL'}>{t('event.type.MEAL')}</MenuItem>
        <MenuItem value={'MEDICAL'}>{t('event.type.MEDICAL')}</MenuItem>
        <MenuItem value={'INCIDENT'}>{t('event.type.INCIDENT')}</MenuItem>
        <MenuItem value={'WEIGH'}>{t('event.type.WEIGH')}</MenuItem>
        <MenuItem value={'MOULT'}>{t('event.type.MOULT')}</MenuItem>
      </Select>
      {stock && stock.length && type === 'MEAL' ? (
        <Select
          className={classes.input}
          labelId="selectStockLabel"
          id="selectStock"
          value={stockId}
          onChange={e => setStockId(e.target.value)}
        >
          <MenuItem value={0}>{t('animalForm.placeholder.stock')}</MenuItem>
          {stock.map(st => {
            return (
              <MenuItem key={`stock_form_${st.id}`} value={st.id}>
                {st.name}
              </MenuItem>
            )
          })}
        </Select>
      ) : null}
      {type === 'MEAL' && animalType !== 'SNAKE' ? (
        <Input
          className={classes.input}
          type="number"
          value={dOld}
          onChange={e => setDOld(e.target.value)}
          name={'old'}
          placeholder={t('event.form.meal.old')}
        />
      ) : null}
      {type === 'MEAL' && animalType !== 'SNAKE' ? (
        <Input
          className={classes.input}
          type="number"
          value={dNew}
          onChange={e => setDNew(e.target.value)}
          name={'new'}
          placeholder={t('event.form.meal.new')}
        />
      ) : null}
      {type === 'MEAL' && animalType === 'SNAKE' ? (
        <Grid container className={classes.input}>
          <Input
            type="number"
            value={dNbStockConsumed}
            onChange={e => setNbStockConsumed(e.target.value)}
            name={'weigh'}
            placeholder={t('event.form.meal.nbStockConsumed')}
          />
          <Input
            type="number"
            value={dWeigh}
            onChange={e => setDWeigh(e.target.value)}
            name={'weigh'}
            placeholder={t('event.form.meal.weigh')}
          />
        </Grid>
      ) : null}
      {type === 'WEIGH' ? (
        <Input
          className={classes.input}
          type="number"
          value={dWeigh}
          onChange={e => setDWeigh(e.target.value)}
          name={'weigh'}
          placeholder={t('event.form.weigh')}
        />
      ) : null}
      {[ 'MEDICAL', 'INCIDENT', ].includes(type) ? (
        <Input
          className={classes.input}
          type="text"
          value={text}
          onChange={e => setText(e.target.value)}
          name={'text'}
          placeholder={'titre'}
        />
      ) : null}
      {[ 'MEDICAL', 'INCIDENT', 'MEAL', ].includes(type) ? (
        <TextareaAutosize
          className={classes.multilineColor}
          rowsMin={2}
          value={dInfo}
          onChange={e => setDInfo(e.target.value)}
          name={'info'}
          placeholder={t('event.form.info')}
        />
      ) : null}
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <KeyboardDatePicker
          className={classes.input}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="startDuration"
          label={t('event.form.startDuration')}
          value={startDuration}
          onChange={setStartDuration}
          KeyboardButtonProps={{
            'aria-label': 'Date ou date de début',
          }}
        />
        {[ 'MEDICAL', ].includes(type) ? (
          <KeyboardDatePicker
            className={classes.input}
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            id="finishDuration"
            label={t('event.form.finishDuration')}
            value={finishDuration}
            onChange={setFinishDuration}
            KeyboardButtonProps={{
              'aria-label': 'date de fin',
            }}
          />
        ) : null}
      </MuiPickersUtilsProvider>
      <ButtonGroup
        className={classes.buttonGroup}
        variant="contained"
        color="primary"
        ref={anchorRef}
      >
        <Button
          color={selectedIndex === 0 ? 'primary' : 'secondary'}
          onClick={selectedIndex === 0 ? handleSave : handleDelete}
        >
          {t(`event.action.${options[selectedIndex]}`)}
        </Button>
        {handleCancel ? (
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <Typography style={{ transform: 'rotate(90deg)', }}>
              {'>'}
            </Typography>
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps, placement, }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={event => handleMenuItemClick(event, index)}
                    >
                      {t(`event.action.${option}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {handleCancel ? (
        <Button
          className={classes.cancelButton}
          onClick={handleCancel}
          variant={'outlined'}
        >
          {t('event.action.cancel')}
        </Button>
      ) : null}
    </Paper>
  )
}
