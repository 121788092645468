import {
  CREATE_ANIMAL_FAILED,
  CREATE_ANIMAL_REQUEST,
  CREATE_ANIMAL_SUCCESS,
  CREATE_EVENT_FAILED,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  CREATE_SCHEDULE_REQUEST,
  CREATE_SCHEDULE_SUCCESS,
  DELETE_ANIMAL_FAILED,
  DELETE_ANIMAL_REQUEST,
  DELETE_ANIMAL_SUCCESS,
  DELETE_EVENT_FAILED,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_SCHEDULE_FAILED,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  GET_ALL_ANIMALS_FAILED,
  GET_ALL_ANIMALS_REQUEST,
  GET_ALL_ANIMALS_SUCCESS,
  GET_ANIMAL_FAILED,
  GET_ANIMAL_REQUEST,
  GET_ANIMAL_SUCCESS,
  GET_EVENT_FAILED,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_SCHEDULE_FAILED,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  UPDATE_ANIMAL_FAILED,
  UPDATE_ANIMAL_REQUEST,
  UPDATE_ANIMAL_SUCCESS,
  UPDATE_EVENT_FAILED,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_SCHEDULE_FAILED,
  UPDATE_SCHEDULE_REQUEST,
  UPDATE_SCHEDULE_SUCCESS,
  UPDATE_MOULT_FAILED,
  UPDATE_MOULT_REQUEST,
  UPDATE_MOULT_SUCCESS,
  RESET_ANIMALS,
} from './constants'

const initialState = {
  error: false,
  loading: false,
  eventLoading: false,
  scheduleLoading: false,
  animals: [],
}

export default (state = initialState, { type, payload, }) => {
  switch (type) {
    case GET_ALL_ANIMALS_SUCCESS:
      return {
        error: false,
        loading: false,
        animals: payload.map(animal => ({
          ...animal,
          events:
            state.animals.filter(animal => animal.id === payload.id).events ||
            [],
          schedules:
            state.animals.filter(animal => animal.id === payload.id).schedules ||
            [],
        })),
      }
    case GET_ALL_ANIMALS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case GET_ALL_ANIMALS_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case GET_ANIMAL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        animals: [
          ...state.animals.filter(animal => animal.id !== payload.id),
          {
            ...payload,
            events:
              state.animals.filter(animal => animal.id === payload.id).events ||
              [],
            schedules:
              state.animals.filter(animal => animal.id === payload.id).schedules ||
              [],
          },
        ],
      }
    case GET_ANIMAL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case GET_ANIMAL_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case CREATE_ANIMAL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        animals: [ ...state.animals, { ...payload, events: [], schedules: [], }, ],
      }
    case CREATE_ANIMAL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case CREATE_ANIMAL_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case UPDATE_ANIMAL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        animals: [
          ...state.animals.filter(e => e.id !== payload.id),
          {
            ...payload,
            events:
              state.animals.filter(animal => animal.id === payload.id).events ||
              [],
            schedules:
              state.animals.filter(animal => animal.id === payload.id).schedules ||
              [],
          },
        ],
      }
    case UPDATE_ANIMAL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case UPDATE_ANIMAL_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case DELETE_ANIMAL_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        animals: [ ...state.animals.filter(e => e.id !== payload.id), ],
      }
    case DELETE_ANIMAL_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case DELETE_ANIMAL_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    // event
    case GET_EVENT_SUCCESS:
      return {
        ...state,
        error: false,
        eventLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id !== parseInt(payload.id)) {
            return animal
          } else {
            return {
              ...animal,
              events: payload.data,
            }
          }
        }),
      }
    case GET_EVENT_REQUEST:
      return {
        ...state,
        error: false,
        eventLoading: true,
      }
    case GET_EVENT_FAILED:
      return {
        ...state,
        error: true,
        eventLoading: false,
      }

    case CREATE_EVENT_SUCCESS:
      return {
        ...state,
        error: false,
        eventLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id === payload.id) {
            return animal
          } else {
            return {
              ...animal,
              events: [ ...animal.events, payload.data, ].sort(
                (a, b) => new Date(b.startDuration) - new Date(a.startDuration)
              ),
            }
          }
        }),
      }
    case CREATE_EVENT_REQUEST:
      return {
        ...state,
        error: false,
        eventLoading: true,
      }
    case CREATE_EVENT_FAILED:
      return {
        ...state,
        error: true,
        eventLoading: false,
      }

    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        error: false,
        eventLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id !== parseInt(payload.id)) {
            return animal
          } else {
            return {
              ...animal,
              events: animal.events.map(event => {
                if (event.id === payload.data.id) {
                  return payload.data
                }
                return event
              }),
            }
          }
        }),
      }
    case UPDATE_EVENT_REQUEST:
      return {
        ...state,
        error: false,
        eventLoading: true,
      }
    case UPDATE_EVENT_FAILED:
      return {
        ...state,
        error: true,
        eventLoading: false,
      }

    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        error: false,
        eventLoading: false,
        animals: state.animals.map(animal => ({
          ...animal,
          events: animal.events.filter(event => event.id !== payload.id),
        })),
      }
    case DELETE_EVENT_REQUEST:
      return {
        ...state,
        error: false,
        eventLoading: true,
      }
    case DELETE_EVENT_FAILED:
      return {
        ...state,
        error: true,
        eventLoading: false,
      }

      // updateMoult
    case UPDATE_MOULT_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        animals: [
          ...state.animals.map(animal => {
            if (animal.id !== payload.id) {
              return animal
            }
            return {
              ...animal,
              isMoult: payload.isMoult,
            }
          }),
        ],
      }
    case UPDATE_MOULT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case UPDATE_MOULT_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

      // schedule
    case GET_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: false,
        scheduleLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id !== parseInt(payload.id)) {
            return animal
          } else {
            return {
              ...animal,
              schedules: payload.data,
            }
          }
        }),
      }
    case GET_SCHEDULE_REQUEST:
      return {
        ...state,
        error: false,
        scheduleLoading: true,
      }
    case GET_SCHEDULE_FAILED:
      return {
        ...state,
        error: true,
        scheduleLoading: false,
      }

    case CREATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: false,
        scheduleLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id === payload.id) {
            return animal
          } else {
            return {
              ...animal,
              schedules: [ ...animal.schedules, payload.data, ].sort(
                (a, b) => new Date(b.startDuration) - new Date(a.startDuration)
              ),
            }
          }
        }),
      }
    case CREATE_SCHEDULE_REQUEST:
      return {
        ...state,
        error: false,
        scheduleLoading: true,
      }
    case CREATE_SCHEDULE_FAILED:
      return {
        ...state,
        error: true,
        scheduleLoading: false,
      }

    case UPDATE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: false,
        scheduleLoading: false,
        animals: state.animals.map(animal => {
          if (animal.id !== parseInt(payload.id)) {
            return animal
          } else {
            return {
              ...animal,
              schedules: animal.schedules.map(schedule => {
                if (schedule.id === payload.data.id) {
                  return payload.data
                }
                return schedule
              }),
            }
          }
        }),
      }
    case UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        error: false,
        scheduleLoading: true,
      }
    case UPDATE_SCHEDULE_FAILED:
      return {
        ...state,
        error: true,
        scheduleLoading: false,
      }

    case DELETE_SCHEDULE_SUCCESS:
      return {
        ...state,
        error: false,
        scheduleLoading: false,
        animals: state.animals.map(animal => ({
          ...animal,
          schedules: animal.schedules.filter(schedule => schedule.id !== payload.id),
        })),
      }
    case DELETE_SCHEDULE_REQUEST:
      return {
        ...state,
        error: false,
        scheduleLoading: true,
      }
    case DELETE_SCHEDULE_FAILED:
      return {
        ...state,
        error: true,
        scheduleLoading: false,
      }
    case RESET_ANIMALS:
      return initialState

    default:
      return { ...state, }
  }
}
