import React, { useEffect, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import NavBar from './NavBar'
import { GET_ALL_ANIMALS, RESET_ANIMALS, } from '../../store/animal/constants'
import { GET_STOCK, RESET_STOCK, } from '../../store/stock/constants'
import { Button, CircularProgress, Grid, makeStyles, } from '@material-ui/core'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(() => ({
  root: {
    overflowX: 'hidden',
  },
  children: {
    paddingRight: '1em',
    paddingLeft: '1em',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
}))

export default function Online (props) {
  const { t, } = useTranslation()
  const classes = styles()

  const [ animalsIsCall, setAnimalsIsCall, ] = useState(false)
  const [ stockIsCall, setStockIsCall, ] = useState(false)

  const { stock, loading: stockLoading, } = useSelector(state => state.stock)
  const { animals, loading: animalsLoading, } = useSelector(
    state => state.animal
  )

  const dispatch = useDispatch()
  useEffect(() => {
    if (!animalsIsCall && !animalsLoading && !animals.length) {
      dispatch({ type: GET_ALL_ANIMALS, })
      setAnimalsIsCall(true)
    }
  }, [ animals, ])

  useEffect(() => {
    if (!stockIsCall && !stockLoading && !stock.length) {
      dispatch({ type: GET_STOCK, })
      setStockIsCall(true)
    }
  }, [ stock, ])

  const handleOnClickResetData = () => {
    setAnimalsIsCall(true)
    dispatch({ type: RESET_ANIMALS, })
    dispatch({ type: GET_ALL_ANIMALS, })
    setStockIsCall(true)
    dispatch({ type: RESET_STOCK, })
    dispatch({ type: GET_STOCK, })
  }

  return (
    <div className={classes.root}>
      {((animalsIsCall && !animalsLoading) || animals.length) &&
      (stock || (stockIsCall && !stockLoading)) ? (
          <>
            <NavBar />
            <Grid item xs={12}>
              <Button onClick={handleOnClickResetData}>{t('animal.resetData')}</Button>
            </Grid>
            <div className={classes.children}>
              {props.children}
            </div>
          </>
        ) : (
          <CircularProgress />
        )}
    </div>
  )
}
