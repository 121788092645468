import React, { useEffect, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { GET_ANIMAL, GET_EVENT, GET_SCHEDULE, } from '../../../store/animal/constants'
import { Checkbox, FormControlLabel, Grid, makeStyles, } from '@material-ui/core'
import { useParams, } from '@reach/router'
import { format, } from 'date-fns'
import Event from './Event'
import EventForm from './EventForm'
import Header from './Header'
import AnimalForm from './AnimalForm'
import WeighChart from './WeighChart'
import CalendarChart from './CalendarChart'
import Schedule from './Schedule'
import ScheduleForm from './ScheduleForm'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(() => ({
  root: {
    margin: 5,
  },
  container: {
    maxHeight: 600,
    maxWidth: 500,
  },
  eventContainer: {
    marginBottom: 15,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  calendar: {
    height: 1000,
  },
}))

export default function Animal () {
  const params = useParams()
  const { t, } = useTranslation()
  const classes = styles()

  const [ updateHeader, setUpdateHeader, ] = useState(false)
  const [ hiddenGraph, setHiddenGraph, ] = useState(true)

  const { animal, loading, eventLoading, scheduleLoading, } = useSelector(
    state => {
      return {
        error: state.animal.error,
        loading: state.animal.loading,
        eventLoading: state.animal.eventLoading,
        scheduleLoading: state.animal.scheduleLoading,
        animal: state.animal.animals.find(
          animal => animal.id === parseInt(params.id)
        ),
      }
    }
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (!loading && !animal) {
      dispatch({ type: GET_ANIMAL, payload: { id: params.id, }, })
    }
    if (!eventLoading && animal && !animal.events.length) {
      dispatch({ type: GET_EVENT, payload: { AnimalId: params.id, }, })
    }
    if (!scheduleLoading && animal && !animal.schedules.length) {
      dispatch({ type: GET_SCHEDULE, payload: { AnimalId: params.id, }, })
    }
  }, [ animal, ])

  return (
    <Grid
      container
      xs={12}
      alignItems={'center'}
      justify={'center'}
      spacing={2}
      className={classes.root}
    >
      {animal ? (<>
        <Grid item xs={12} md={3} className={classes.container}>
          {updateHeader ? (
            <AnimalForm
              animal={animal}
              handleCancel={() => setUpdateHeader(false)}
            />
          ) : (
            <Header
              animal={animal}
              handleUpdate={() => setUpdateHeader(true)}
            />
          )}
        </Grid>
        <Grid item xs={12} md={3} className={[ classes.container, classes.eventContainer, ]}>
          {animal.schedules.map(schedule => (
            <Schedule key={`schedule${schedule.id}`} schedule={schedule} />
          ))}
          <ScheduleForm/>
        </Grid>
        <Grid item xs={12} md={3} className={classes.container}>
          <EventForm
            animalType={animal.type}
            animalStockId={animal.stockId}
            animalStockConsumed={animal.nbStockByMeal}
          />
        </Grid>
        { animal.events ? (
          <Grid
            item
            xs={12}
            md={3}
            className={[ classes.container, classes.eventContainer, ]}
          >
            {animal.events.map(event => (
              <Event
                key={`event${event.id}`}
                event={event}
                AnimalId={animal.id}
                animalType={animal.type}
                animalStockId={animal.stockId}
                nbStockByMeal={animal.nbStockByMeal}
              />
            ))}
          </Grid>
        ) : null}
      </>
      ) : null}
      <Grid container
        xs={12}
        alignItems={'center'}
        justify={'center'}
        spacing={2}
        className={classes.root}>
        <FormControlLabel
          control={
            <Checkbox
              checked={hiddenGraph}
              onChange={() => setHiddenGraph(!hiddenGraph)}
              name={'hiddenGraph'}
              color={'primary'}
            />
          }
          label={t('event.chart.isNotHidden')}
        />
      </Grid>
      <Grid hidden={hiddenGraph} xs={12} item>
        <WeighChart
          weighs={
            animal.type === 'SNAKE'
              ? animal.events.filter(e => e.type === 'WEIGH')
              : animal.events.filter(
                e => e.type === 'MEAL' && e.data.nbStockConsumed
              )
          }
          moults={animal.events.filter(e => e.type === 'MOULT')}
          animalType={animal.type}
        />
      </Grid>
      <Grid hidden={hiddenGraph} xs={12} className={classes.calendar}>
        <CalendarChart
          data={animal.events.map(e => ({
            day: format(new Date(e.startDuration), 'yyyy-MM-dd'),
            value:
              e.type === 'MEDICAL'
                ? 300
                : e.type === 'INCIDENT'
                  ? 200
                  : e.type === 'MOULT'
                    ? 100
                    : e.type === 'WEIGH'
                      ? 75
                      : e.type === 'MEAL'
                        ? 1
                        : 0,
          }))}
        />
      </Grid>
    </Grid>
  )
}
