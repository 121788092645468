import { applyMiddleware, combineReducers, compose, createStore as createReduxStore, } from 'redux'
import createSagaMiddleware from 'redux-saga'

import globalReducer from './global/reducer'
import animalReducer from './animal/reducer'
import stockReducer from './stock/reducer'
import globalWatcher from './global/saga'
import animalWatcher from './animal/saga'
import stockWatcher from './stock/saga'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const createStore = () => {
  const middlewares = [ sagaMiddleware, ]

  const appReducer = combineReducers({
    global: globalReducer,
    animal: animalReducer,
    stock: stockReducer,
  })

  const rootReducer = (state, action) => {
    return appReducer(state, action)
  }

  const redux = createReduxStore(
    rootReducer,
    composeEnhancer(applyMiddleware(...middlewares)),
  )

  sagaMiddleware.run(globalWatcher)
  sagaMiddleware.run(animalWatcher)
  sagaMiddleware.run(stockWatcher)

  return redux
}

export const store = createStore()
