export const RESET_STOCK = 'RESET_STOCK'

export const GET_STOCK = 'GET_STOCK'
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS'
export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST'
export const GET_STOCK_FAILED = 'GET_STOCK_FAILED'

export const CREATE_STOCK = 'CREATE_STOCK'
export const CREATE_STOCK_SUCCESS = 'CREATE_STOCK_SUCCESS'
export const CREATE_STOCK_REQUEST = 'CREATE_STOCK_REQUEST'
export const CREATE_STOCK_FAILED = 'CREATE_STOCK_FAILED'

export const UPDATE_STOCK = 'UPDATE_STOCK'
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS'
export const UPDATE_STOCK_REQUEST = 'UPDATE_STOCK_REQUEST'
export const UPDATE_STOCK_FAILED = 'UPDATE_STOCK_FAILED'

export const DELETE_STOCK = 'DELETE_STOCK'
export const DELETE_STOCK_SUCCESS = 'DELETE_STOCK_SUCCESS'
export const DELETE_STOCK_REQUEST = 'DELETE_STOCK_REQUEST'
export const DELETE_STOCK_FAILED = 'DELETE_STOCK_FAILED'
