import React from 'react'
import { Router, } from '@reach/router'
import Login from './pages/Login'
import Online from './pages/app/Online'
import Home from './pages/app/Home'
import Stock from './pages/app/Stock'
import Animal from './pages/app/Animal'
import { useSelector, } from 'react-redux'

function App () {
  const { token, } = useSelector(state => state.global)

  return (
    <Router>
      {token ? (
        <Online path={'/'}>
          <Home path={'/'} />
          <Stock path={'stock'} />
          <Animal path={'animal/:id'} />
        </Online>
      ) : null}
      <Login default />
    </Router>
  )
}

export default App
