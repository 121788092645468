import API from '../../config/axios'

export const requestStock = async () => {
  try {
    const request = await API.get('/private/stock')
    return request.data
  } catch (error) {
    return false
  }
}

export const createStock = async payload => {
  try {
    const request = await API.post('/private/stock', payload)
    return request.data
  } catch (error) {
    return false
  }
}

export const updateStock = async payload => {
  try {
    await API.put('/private/stock', payload)
    return true
  } catch (error) {
    return false
  }
}

export const deleteStock = async payload => {
  try {
    await API.delete('/private/stock', { params: payload, })
    return true
  } catch (error) {
    return false
  }
}
