export const RESET_ANIMALS = 'RESET_ANIMALS'

export const GET_ALL_ANIMALS = 'GET_ALL_ANIMALS'
export const GET_ALL_ANIMALS_SUCCESS = 'GET_ALL_ANIMALS_SUCCESS'
export const GET_ALL_ANIMALS_REQUEST = 'GET_ALL_ANIMALS_REQUEST'
export const GET_ALL_ANIMALS_FAILED = 'GET_ALL_ANIMALS_FAILED'

export const GET_ANIMAL = 'GET_ANIMAL'
export const GET_ANIMAL_SUCCESS = 'GET_ANIMAL_SUCCESS'
export const GET_ANIMAL_REQUEST = 'GET_ANIMAL_REQUEST'
export const GET_ANIMAL_FAILED = 'GET_ANIMAL_FAILED'

export const CREATE_ANIMAL = 'CREATE_ANIMAL'
export const CREATE_ANIMAL_SUCCESS = 'CREATE_ANIMAL_SUCCESS'
export const CREATE_ANIMAL_REQUEST = 'CREATE_ANIMAL_REQUEST'
export const CREATE_ANIMAL_FAILED = 'CREATE_ANIMAL_FAILED'

export const UPDATE_ANIMAL = 'UPDATE_ANIMAL'
export const UPDATE_ANIMAL_SUCCESS = 'UPDATE_ANIMAL_SUCCESS'
export const UPDATE_ANIMAL_REQUEST = 'UPDATE_ANIMAL_REQUEST'
export const UPDATE_ANIMAL_FAILED = 'UPDATE_ANIMAL_FAILED'

export const DELETE_ANIMAL = 'DELETE_ANIMAL'
export const DELETE_ANIMAL_SUCCESS = 'DELETE_ANIMAL_SUCCESS'
export const DELETE_ANIMAL_REQUEST = 'DELETE_ANIMAL_REQUEST'
export const DELETE_ANIMAL_FAILED = 'DELETE_ANIMAL_FAILED'

// event
export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST'
export const GET_EVENT_FAILED = 'GET_EVENT_FAILED'

export const CREATE_EVENT = 'CREATE_EVENT'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST'
export const CREATE_EVENT_FAILED = 'CREATE_EVENT_FAILED'

export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST'
export const UPDATE_EVENT_FAILED = 'UPDATE_EVENT_FAILED'

export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST'
export const DELETE_EVENT_FAILED = 'DELETE_EVENT_FAILED'

// update moult status
export const UPDATE_MOULT = 'UPDATE_MOULT'
export const UPDATE_MOULT_SUCCESS = 'UPDATE_MOULT_SUCCESS'
export const UPDATE_MOULT_REQUEST = 'UPDATE_MOULT_REQUEST'
export const UPDATE_MOULT_FAILED = 'UPDATE_MOULT_FAILED'

// schedule
export const GET_SCHEDULE = 'GET_SCHEDULE'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST'
export const GET_SCHEDULE_FAILED = 'GET_SCHEDULE_FAILED'

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE'
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS'
export const CREATE_SCHEDULE_REQUEST = 'CREATE_SCHEDULE_REQUEST'
export const CREATE_SCHEDULE_FAILED = 'CREATE_SCHEDULE_FAILED'

export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS'
export const UPDATE_SCHEDULE_REQUEST = 'UPDATE_SCHEDULE_REQUEST'
export const UPDATE_SCHEDULE_FAILED = 'UPDATE_SCHEDULE_FAILED'

export const DELETE_SCHEDULE = 'DELETE_SCHEDULE'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST'
export const DELETE_SCHEDULE_FAILED = 'DELETE_SCHEDULE_FAILED'
