import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Link, } from '@reach/router'
import { Avatar, Grow, makeStyles, Paper, Popper, } from '@material-ui/core'
import { useSelector, } from 'react-redux'

const styles = makeStyles(theme => ({
  link: {
    color: theme.palette.info.dark,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  typography: {
    color: theme.palette.info.dark,
  },
  popper: {
    zIndex: 99,
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
  },
}))

function Element (props) {
  const classes = styles()
  const { to, name, picture, className, onClick, } = props

  return (
    <Grid direction={'column'} container className={[ className, ]}>
      <Link onClick={onClick} className={classes.link} to={to}>
        {picture ? <Avatar src={picture}/> : null}
        <Typography variant={'overline'}>{name}</Typography>
      </Link>
    </Grid>
  )
}

export default function NavBar (props) {
  const { animals, } = useSelector(state => state.animal)
  const [ open, setOpen, ] = React.useState(false)
  const classes = styles()

  const anchorRef = React.useRef(null)

  return (
    <AppBar position={'static'} color={'default'}>
      <Toolbar>
        <Element to={''} name={'Home'} onClick={() => setOpen(false)}
        />
        <Element to={'stock'} name={'Stock'} onClick={() => setOpen(false)}
        />
        <Grid ref={anchorRef} container>
          <Typography
            ref={anchorRef}
            variant={'overline'}
            className={classes.typography}
            onClick={() => setOpen(!open)}
          >
            Animals
          </Typography>
        </Grid>
        <Popper
          open={open}
          role={undefined}
          anchorEl={anchorRef.current}
          transition
          className={classes.popper}
        >
          {({ TransitionProps, placement, }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
              className={classes.paper}
            >
              <Paper>
                {animals.length
                  ? animals.map(animal => (
                    <Element
                      key={`nav_bar_${animal.id}`}
                      picture={animal.picture}
                      name={animal.name}
                      to={`animal/${animal.id}`}
                      className={classes.grid}
                      onClick={() => setOpen(false)}
                    />
                  ))
                  : null}
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </AppBar>
  )
}
