import React, { useEffect, useState, } from 'react'
import { useDispatch, useSelector, } from 'react-redux'
import { CREATE_STOCK, DELETE_STOCK, UPDATE_STOCK, } from '../../../store/stock/constants'
import { Button, Card, CardActions, CardContent, Grid, Input, makeStyles, } from '@material-ui/core'
import Product from './Product'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    margin: 10,
  },
  card: {
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    maxWidth: 200,
    marginBottom: 25,
  },
  cardAction: {
    justifyContent: 'center',
  },
  stockContainer: {
    justifyContent: 'center',
  },
}))

export default function Stock (props) {
  const { t, } = useTranslation()
  const classes = styles()

  const [ name, setName, ] = useState('')
  const [ number, setNumber, ] = useState('')
  const { stock, error, loading, } = useSelector(state => state.stock)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loading && (name || !!number)) {
      setName('')
      setNumber('')
    }
  }, [ loading, ])

  const createStock = () => {
    if (name && !!number) {
      dispatch({
        type: CREATE_STOCK,
        payload: {
          name,
          number,
        },
      })
    }
  }

  const updateStock = payload => {
    dispatch({
      type: UPDATE_STOCK,
      payload,
    })
  }

  const deleteStock = payload => {
    dispatch({
      type: DELETE_STOCK,
      payload,
    })
  }

  return (
    <Grid container className={classes.root} alignItems={'center'}>
      <Card item className={classes.card}>
        <CardContent>
          <Input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            name={'name'}
            placeholder={t('stock.placeholder.name')}
          />
          <Input
            type="number"
            value={number}
            onChange={e => setNumber(e.target.value)}
            name={'number'}
            placeholder={t('stock.placeholder.quantity')}
          />
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button variant={'contained'} color={'primary'} onClick={createStock}>
            {t('stock.action.add')}
          </Button>
        </CardActions>
      </Card>
      <Grid xs={12} container className={classes.stockContainer}>
        {stock
          ? stock.map(e => (
            <Product
              updateStock={updateStock}
              deleteStock={deleteStock}
              stock={e}
              key={e.id}
            />
          ))
          : null}
      </Grid>
    </Grid>
  )
}
