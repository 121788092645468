import React, { useState, } from 'react'
import { format, } from 'date-fns'
import { fr, } from 'date-fns/locale'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { useTranslation, } from 'react-i18next'
import ScheduleForm from './ScheduleForm'

const styles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    paddingBottom: 10,
  },
}))

export default function Schedule (props) {
  const { schedule, } = props
  const { t, } = useTranslation()
  const classes = styles()

  const [ isUpdate, setIsUpdate, ] = useState(false)

  return (
    <Grid container spacing={2} className={classes.grid}>
      {isUpdate ? (
        <ScheduleForm
          item
          schedule={schedule}
          handleCancel={() => setIsUpdate(false)}
        />
      ) : (
        <Card>
          <CardContent>
            <Typography>
              {t('schedule.name', { name: schedule.name, })}
            </Typography>
            <Typography>
              {t(`schedule.type.${schedule.recurrency.split('/')[1]}`, {
                number: schedule.recurrency.split('/')[0],
              })}
            </Typography>
            <Typography color="textSecondary" variant={'overline'}>
              {t('schedule.lastReminder', {
                date: format(new Date(schedule.lastReminder), 'dd MMMM yyyy', {
                  locale: fr,
                }),
              })}
            </Typography>
            <Typography>
              {t(`schedule.manualUpdate.${schedule.manualUpdate}`)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => setIsUpdate(!isUpdate)}>
              {t('schedule.update')}
            </Button>
          </CardActions>
        </Card>
      )}
    </Grid>
  )
}
