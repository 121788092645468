import React from 'react'
import { ResponsiveCalendar, } from '@nivo/calendar'
import { min, max, } from 'date-fns'
import { useTheme, } from '@material-ui/core'

export default function WeighChart (props) {
  const { data, } = props
  const theme = useTheme()

  return (
    <ResponsiveCalendar
      data={data}
      from={min(data.map(e => new Date(e.day)))}
      to={max(data.map(e => new Date(e.day)))}
      emptyColor={theme.palette.divider}
      colors={[ theme.palette.success.light, theme.palette.success.dark, theme.palette.error.light, theme.palette.error.dark, ]}
      margin={{ top: 40, right: 40, bottom: 40, left: 40, }}
      yearSpacing={40}
      monthBorderColor={theme.palette.info.dark}
      dayBorderWidth={2}
      dayBorderColor={theme.palette.divider}
      legends={[
        {
          anchor: 'bottom-right',
          direction: 'row',
          translateY: 36,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: 'right-to-left',
        },
      ]}
    />
  )
}
