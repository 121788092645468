import 'date-fns'
import React, { useState, } from 'react'
import {
  Button,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { fr, } from 'date-fns/locale'
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers'
import { useDispatch, } from 'react-redux'
import { CREATE_SCHEDULE, UPDATE_SCHEDULE, } from '../../../store/animal/constants'
import { useTranslation, } from 'react-i18next'
import { useParams, } from '@reach/router'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    padding: 5,
  },
  input: {
    marginBottom: 10,
  },
  button: {
    marginLeft: 100,
    marginRight: 100,
    marginBottom: 10,
  },
}))

export default function ScheduleForm (props) {
  const { t, } = useTranslation()
  const classes = styles()
  const params = useParams()

  const schedule = props.schedule || {}
  const { handleCancel, } = props

  const [ name, setName, ] = useState(schedule.name || '')
  const [ manualUpdate, setManualUpdate, ] = useState(schedule.manualUpdate || false)
  const [ recurrencyNumber, setRecurrencyNumber, ] = useState(
    handleCancel ? schedule.recurrency.split('/')[0] : null
  )
  const [ recurrencyString, setRecurrencyString, ] = useState(
    handleCancel ? schedule.recurrency.split('/')[1] : 'months'
  )
  const [ lastReminder, setLastReminder, ] = useState(
    schedule.lastReminder ? new Date(schedule.lastReminder) : new Date()
  )

  const dispatch = useDispatch()

  const handleSave = () => {
    if (
      name &&
      lastReminder &&
      recurrencyNumber &&
      recurrencyString
    ) {
      const payload = {
        name,
        lastReminder,
        manualUpdate,
        recurrency: `${recurrencyNumber}/${recurrencyString}`,
        AnimalId: params.id,
      }
      if (schedule.id) {
        dispatch({
          type: UPDATE_SCHEDULE,
          payload: {
            ...payload,
            id: schedule.id,
          },
        })
        handleCancel()
      } else {
        dispatch({
          type: CREATE_SCHEDULE,
          payload,
        })
        setLastReminder(new Date())
        setName('')
        setManualUpdate(false)
        setRecurrencyNumber(null)
        setRecurrencyString('months')
      }
    }
  }

  return (
    <Paper container className={classes.root}>
      <Input
        className={classes.input}
        type="text"
        value={name}
        onChange={e => setName(e.target.value)}
        name={'nom'}
        placeholder={t('scheduleForm.placeholder.name')}
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <KeyboardDatePicker
          className={classes.input}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="birthday"
          label={t('scheduleForm.placeholder.lastReminder')}
          value={lastReminder}
          onChange={setLastReminder}
          KeyboardButtonProps={{
            'aria-label': 'Date de naissance',
          }}
        />
      </MuiPickersUtilsProvider>
      <Grid className={classes.input}>
        <Typography>{t('scheduleForm.placeholder.recurrency')}</Typography>
        <Input
          type="number"
          value={recurrencyNumber}
          onChange={e => setRecurrencyNumber(e.target.value)}
          name={'recurrencyNumber'}
          placeholder={t('scheduleForm.placeholder.recurrencyNumber')}
        />
        <Select
          labelId="selectRecurrencyLabel"
          id="selectRecurrency"
          value={recurrencyString}
          onChange={e => setRecurrencyString(e.target.value)}
        >
          <MenuItem value={'days'}>
            {t('scheduleForm.recurrency.day')}
          </MenuItem>
          <MenuItem value={'weeks'}>
            {t('scheduleForm.recurrency.week')}
          </MenuItem>
          <MenuItem value={'months'}>
            {t('scheduleForm.recurrency.month')}
          </MenuItem>
          <MenuItem value={'years'}>
            {t('scheduleForm.recurrency.years')}
          </MenuItem>
        </Select>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={manualUpdate}
            onChange={() => setManualUpdate(!manualUpdate)}
            name={'manualUpdate'}
            color={'primary'}
          />
        }
        label={t('scheduleForm.manualUpdate')}
      />
      <Button
        className={classes.button}
        onClick={handleSave}
        variant="contained"
        color={'primary'}
        disabled={
          !name ||
          !lastReminder ||
          !recurrencyString ||
          !recurrencyNumber
        }
      >
        {t(`scheduleForm.action.${schedule.id ? 'update' : 'add'}`)}
      </Button>
      {handleCancel ? (
        <Button
          className={classes.button}
          onClick={handleCancel}
          variant="contained"
          color={'secondary'}
        >
          {t('scheduleForm.action.cancel')}
        </Button>
      ) : null}
    </Paper>
  )
}
