import 'date-fns'
import React, { useState, } from 'react'
import {
  Button,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { fr, } from 'date-fns/locale'
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers'
import { useDispatch, useSelector, } from 'react-redux'
import { CREATE_ANIMAL, UPDATE_ANIMAL, } from '../../../store/animal/constants'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
    padding: 5,
  },
  input: {
    marginBottom: 10,
  },
  button: {
    marginLeft: 100,
    marginRight: 100,
    marginBottom: 10,
  },
  multilineColor: {
    background: theme.palette.background.default,
    color: theme.palette.common.white,
  },
}))

export default function AnimalForm (props) {
  const { t, } = useTranslation()
  const classes = styles()

  const animal = props.animal || {}
  const { handleCancel, } = props

  const { stock, } = useSelector(state => state.stock)

  const [ name, setName, ] = useState(animal.name || '')
  const [ picture, setPicture, ] = useState('')
  const [ type, setType, ] = useState(animal.type || 0)
  const [ description, setDescription, ] = useState(animal.description || '')
  const [ mealRecurencyNumber, setMealRecurencyNumber, ] = useState(
    (handleCancel && animal.type === 'SNAKE') ? animal.mealRecurency.split('/')[0] : null
  )
  const [ mealRecurencyString, setMealRecurencyString, ] = useState(
    (handleCancel && animal.type === 'SNAKE') ? animal.mealRecurency.split('/')[1] : 'days'
  )
  const [ birthday, setBirthday, ] = useState(
    animal.birthday ? new Date(animal.birthday) : new Date()
  )
  const [ nbStockByMeal, setNbStockByMeal, ] = useState(
    animal.nbStockByMeal || null
  )
  const [ stockId, setStockId, ] = useState(animal.stockId || 0)

  const dispatch = useDispatch()

  const changeType = event => setType(event.target.value)
  const changeStockType = event => setStockId(event.target.value)

  const handleSave = () => {
    if (
      name &&
      type &&
      birthday &&
      nbStockByMeal &&
      description &&
      (type !== 'SNAKE' || (mealRecurencyNumber && mealRecurencyString))
    ) {
      const payload = {
        name,
        type,
        birthday,
        nbStockByMeal,
        stockId,
        picture,
        description,
        mealRecurency: `${mealRecurencyNumber}/${mealRecurencyString}`,
      }
      if (animal.id) {
        dispatch({
          type: UPDATE_ANIMAL,
          payload: {
            ...payload,
            id: animal.id,
          },
        })
        handleCancel()
      } else {
        dispatch({
          type: CREATE_ANIMAL,
          payload,
        })
        setBirthday(new Date())
        setName('')
        setType('')
        setNbStockByMeal(null)
        setDescription('')
        setMealRecurencyString('days')
        setMealRecurencyNumber(null)
      }
    }
  }

  return (
    <Paper container className={classes.root}>
      <Input
        className={classes.input}
        type="text"
        value={name}
        onChange={e => setName(e.target.value)}
        name={'nom'}
        placeholder={t('animalForm.placeholder.name')}
      />
      <TextareaAutosize
        className={classes.multilineColor}
        rowsMin={2}
        value={description}
        onChange={e => setDescription(e.target.value)}
        name={'info'}
        placeholder={t('animalForm.placeholder.description')}
      />
      <Select
        className={classes.input}
        labelId="selectTypeLabel"
        id="selectType"
        value={type}
        onChange={changeType}
      >
        <MenuItem value={0}>{t('animalForm.placeholder.type')}</MenuItem>
        <MenuItem value={'CAT'}>{t('animal.type.CAT')}</MenuItem>
        <MenuItem value={'DOG'}>{t('animal.type.DOG')}</MenuItem>
        <MenuItem value={'SNAKE'}>{t('animal.type.SNAKE')}</MenuItem>
        <MenuItem value={'TORTOISE'}>{t('animal.type.TORTOISE')}</MenuItem>
      </Select>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
        <KeyboardDatePicker
          className={classes.input}
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="birthday"
          label={t('animalForm.placeholder.birthday')}
          value={birthday}
          onChange={setBirthday}
          KeyboardButtonProps={{
            'aria-label': 'Date de naissance',
          }}
        />
      </MuiPickersUtilsProvider>
      {stock && stock.length ? (
        <Select
          className={classes.input}
          labelId="selectStockLabel"
          id="selectStock"
          value={stockId}
          onChange={changeStockType}
        >
          <MenuItem value={0}>{t('animalForm.placeholder.stock')}</MenuItem>
          {stock.map(st => {
            return (
              <MenuItem key={`stock_form_${st.id}`} value={st.id}>
                {st.name}
              </MenuItem>
            )
          })}
        </Select>
      ) : null}
      <Input
        className={classes.input}
        type="number"
        value={nbStockByMeal}
        onChange={e => setNbStockByMeal(e.target.value)}
        name={'nbStockByMeal'}
        placeholder={t('animalForm.placeholder.nbStockByMeal')}
      />
      { type === 'SNAKE' ? <Grid className={classes.input}>
        <Typography>{t('animalForm.placeholder.mealRecurency')}</Typography>
        <Input
          type="number"
          value={mealRecurencyNumber}
          onChange={e => setMealRecurencyNumber(e.target.value)}
          name={'mealRecurencyNumber'}
          placeholder={t('animalForm.placeholder.mealRecurencyNumber')}
        />
        <Select
          labelId="selectMealRecurencyLabel"
          id="selectMealRecurency"
          value={mealRecurencyString}
          onChange={e => setMealRecurencyString(e.target.value)}
        >
          <MenuItem value={'days'}>
            {t('animalForm.mealRecurency.day')}
          </MenuItem>
          <MenuItem value={'weeks'}>
            {t('animalForm.mealRecurency.week')}
          </MenuItem>
          <MenuItem value={'months'}>
            {t('animalForm.mealRecurency.month')}
          </MenuItem>
        </Select>
      </Grid> : null}
      <Input
        className={classes.input}
        type={'file'}
        name={'picture'}
        onChange={e => setPicture(e.target.files[0])}
      />
      <Button
        className={classes.button}
        onClick={handleSave}
        variant="contained"
        color={'primary'}
        disabled={
          !name ||
          !type ||
          !birthday ||
          !nbStockByMeal ||
          !description ||
          (type === 'SNAKE' && (!mealRecurencyString || !mealRecurencyNumber)) ||
          (!animal.id && !picture)
        }
      >
        {t(`animalForm.action.${animal.id ? 'update' : 'add'}`)}
      </Button>
      {handleCancel ? (
        <Button
          className={classes.button}
          onClick={handleCancel}
          variant="contained"
          color={'secondary'}
        >
          {t('animalForm.action.cancel')}
        </Button>
      ) : null}
    </Paper>
  )
}
