import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, } from '@material-ui/core/styles'
import './theme/index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider, } from 'react-redux'
import { store, } from './store'
import MUITheme from './theme/MUITheme'
import './services/i18n'
import { CssBaseline, } from '@material-ui/core'

ReactDOM.render(
  <React.StrictMode>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <MuiThemeProvider theme={MUITheme}>
      <CssBaseline />
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
