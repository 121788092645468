import React, { useState, } from 'react'
import { format, } from 'date-fns'
import { fr, } from 'date-fns/locale'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import EventForm from './EventForm'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    paddingBottom: 10,
  },
  meal: {
    backgroundColor: theme.palette.primary.dark,
  },
  medical: {
    backgroundColor: theme.palette.error.dark,
  },
  incident: {
    backgroundColor: theme.palette.warning.dark,
  },
  weigh: {
    backgroundColor: theme.palette.info.dark,
  },
  moult: {
    backgroundColor: theme.palette.success.dark,
  },
  info: {
    whiteSpace: 'pre-line',
  },
}))

export default function Event (props) {
  const { event, animalType, animalStockId, nbStockByMeal, } = props
  const { t, } = useTranslation()
  const classes = styles()

  const [ isUpdate, setIsUpdate, ] = useState(false)

  return (
    <Grid container spacing={2} className={classes.grid}>
      {isUpdate ? (
        <EventForm
          item
          event={event}
          animalType={animalType}
          handleCancel={() => setIsUpdate(false)}
          animalStockId={animalStockId}
          nbStockConsumed={nbStockByMeal}
        />
      ) : (
        <Card className={classes[event.type.toLowerCase()]}>
          <CardContent>
            <Typography>{t(`event.type.${event.type}`)}</Typography>
            <Typography color="textSecondary" variant={'overline'}>
              {format(new Date(event.startDuration), 'dd MMMM yyyy', {
                locale: fr,
              })}
            </Typography>
            {[ 'MEDICAL', ].includes(event.type) ? (
              <Typography color="textSecondary" variant={'overline'}>
                {t('event.medical.finishDuration', {
                  date: format(new Date(event.finishDuration), 'dd MMMM yyyy', {
                    locale: fr,
                  }),
                })}
              </Typography>
            ) : null}
            {event.type === 'MEAL' && animalType !== 'SNAKE' && event.data.old ? (
              <Typography variant={'body2'}>
                {t('event.meal.old', { old: event.data.old, })}
              </Typography>
            ) : null}
            {event.type === 'MEAL' && animalType !== 'SNAKE' && event.data.new ? (
              <Typography variant={'body2'}>
                {t('event.meal.new', { new: event.data.new, })}
              </Typography>
            ) : null}
            {event.type === 'MEAL' && event.mealName ? (
              <Typography variant={'body2'}>
                {t(
                  `event.meal.name.${animalType === 'SNAKE' ? 'snake' : 'all'}`,
                  { number: event.data.nbStockConsumed, name: event.mealName, }
                )}
              </Typography>
            ) : null}
            {event.type === 'MEAL' &&
            animalType === 'SNAKE' &&
            event.data.weigh ? (
                <Typography variant={'body2'}>
                  {t('event.meal.weigh', { weigh: event.data.weigh, })}
                </Typography>
              ) : null}
            {event.type === 'WEIGH' ? (
              <Typography variant={'body2'}>{event.data.weigh}g</Typography>
            ) : null}
            {[ 'MEDICAL', 'INCIDENT', ].includes(event.type) ? (
              <Typography variant={'h6'}>{event.text}</Typography>
            ) : null}
            {[ 'MEDICAL', 'INCIDENT', 'MEAL', ].includes(event.type) &&
            event.data &&
            event.data.info ? (
                <Typography variant={'subtitle2'} className={classes.info}>
                  {event.data.info}
                </Typography>
              ) : null}
          </CardContent>
          <CardActions>
            <Button onClick={() => setIsUpdate(!isUpdate)}>
              {t('event.action.update')}
            </Button>
          </CardActions>
        </Card>
      )}
    </Grid>
  )
}
