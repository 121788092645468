import React from 'react'
import Chart from 'react-apexcharts'
import { format, } from 'date-fns'
import { useTranslation, } from 'react-i18next'

export default function WeighChart (props) {
  const { t, } = useTranslation()
  const { weighs, moults, animalType, } = props
  const series = [
    {
      name: t('event.chart.weighMoult.series.weigh'),
      data: weighs.map(weigh => [
        new Date(weigh.startDuration).getTime(),
        parseInt(animalType === 'SNAKE' ? weigh.data.weigh : weigh.data.nbStockConsumed),
      ],),
      type: 'area',
    },
  ]

  if (moults && moults.length) {
    series.push({
      name: t('event.chart.weighMoult.series.moult'),
      data: moults.map(moult => [ new Date(moult.startDuration).getTime(), 1, ]),
      type: 'scatter',
    },)
  }

  return (
    <Chart
      series={series}
      type={'area'}
      options={{
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: [ 0, 6, ],
        },
        fill: {
          type: [ 'gradient', 'solid', ],
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [ 20, 100, ],
          },
        },
        yaxis: [
          {
            labels: {
              style: {
                colors: '#8e8da4',
              },
              offsetX: 0,
              formatter: function (val) {
                return val
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          {
            seriesName: t('event.chart.weighMoult.series.moult'),
            opposite: true,
          },
        ],
        xaxis: {
          type: 'datetime',
          tickAmount: 8,
          labels: {
            rotate: -15,
            rotateAlways: true,
            formatter: (val, timestamp) => {
              return format(new Date(timestamp), 'd MM yy')
            },
          },
        },
        title: {
          text: t(`event.chart.weighMoult.title.${animalType}`),
          align: 'left',
          offsetX: 14,
        },
        tooltip: {
          enabled: true,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          offsetX: -10,
        },
      }}
      height={500}
      width={'100%'}
    />
  )
}
