import { call, put, takeLatest, } from 'redux-saga/effects'

import {
  CREATE_STOCK,
  CREATE_STOCK_FAILED,
  CREATE_STOCK_REQUEST,
  CREATE_STOCK_SUCCESS,
  DELETE_STOCK,
  DELETE_STOCK_FAILED,
  DELETE_STOCK_REQUEST,
  DELETE_STOCK_SUCCESS,
  GET_STOCK,
  GET_STOCK_FAILED,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  UPDATE_STOCK,
  UPDATE_STOCK_FAILED,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
} from './constants'

import { createStock, deleteStock, requestStock, updateStock, } from './actions'

export default function * stockWatcher () {
  yield takeLatest([ GET_STOCK, ], getStockSaga)
  yield takeLatest([ CREATE_STOCK, ], createStockSaga)
  yield takeLatest([ UPDATE_STOCK, ], updateStockSaga)
  return yield takeLatest([ DELETE_STOCK, ], deleteStockSaga)
}

function * getStockSaga () {
  yield put({ type: GET_STOCK_REQUEST, })
  const data = yield call(requestStock)
  if (!data) {
    return yield put({
      type: GET_STOCK_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: GET_STOCK_SUCCESS,
    payload: data,
  })
}

function * createStockSaga ({ payload, }) {
  yield put({ type: CREATE_STOCK_REQUEST, })
  const data = yield call(createStock, payload)
  if (!data) {
    return yield put({
      type: CREATE_STOCK_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: CREATE_STOCK_SUCCESS,
    payload: data,
  })
}

function * updateStockSaga ({ payload, }) {
  yield put({ type: UPDATE_STOCK_REQUEST, })
  const data = yield call(updateStock, payload)
  if (!data) {
    return yield put({
      type: UPDATE_STOCK_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: UPDATE_STOCK_SUCCESS,
    payload,
  })
}

function * deleteStockSaga ({ payload, }) {
  yield put({ type: DELETE_STOCK_REQUEST, })
  const data = yield call(deleteStock, payload)
  if (!data) {
    return yield put({
      type: DELETE_STOCK_FAILED,
      payload: {},
    })
  }
  return yield put({
    type: DELETE_STOCK_SUCCESS,
    payload,
  })
}
