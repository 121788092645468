import API from '../../config/axios'

export const requestAllAnimals = async () => {
  try {
    const request = await API.get('/private/user')
    return request.data
  } catch (error) {
    return false
  }
}

export const requestAnimal = async payload => {
  try {
    const request = await API.get('/private/animal', { params: payload, })
    return request.data
  } catch (error) {
    return false
  }
}

export const createAnimal = async payload => {
  try {
    const data = new FormData()
    data.append('picture', payload.picture)
    data.append('name', payload.name)
    data.append('description', payload.description)
    if (payload.type === 'SNAKE')data.append('mealRecurency', payload.mealRecurency)
    data.append('type', payload.type)
    data.append('birthday', payload.birthday)
    data.append('nbStockByMeal', payload.nbStockByMeal)
    data.append('StockId', parseInt(payload.stockId))

    const request = await API.post('/private/animal', data)
    return request.data
  } catch (error) {
    return false
  }
}

export const updateAnimal = async payload => {
  try {
    const data = new FormData()
    if (payload.picture) data.append('picture', payload.picture)
    data.append('name', payload.name)
    data.append('description', payload.description)
    if (payload.type === 'SNAKE')data.append('mealRecurency', payload.mealRecurency)
    data.append('type', payload.type)
    data.append('birthday', payload.birthday)
    data.append('nbStockByMeal', payload.nbStockByMeal)
    data.append('StockId', parseInt(payload.stockId))
    data.append('id', payload.id)

    const req = await API.put('/private/animal', data)
    return req.data
  } catch (error) {
    return false
  }
}

export const deleteAnimal = async payload => {
  try {
    await API.delete('/private/animal', { params: payload, })
    return true
  } catch (error) {
    return false
  }
}

export const requestEvent = async payload => {
  try {
    const request = await API.get('/private/event', { params: payload, })
    return request.data
  } catch (error) {
    return false
  }
}

// event
export const createEvent = async payload => {
  try {
    const request = await API.post('/private/event', payload)
    return request.data
  } catch (error) {
    return false
  }
}

export const updateEvent = async payload => {
  try {
    const req = await API.put('/private/event', payload)
    return req.data
  } catch (error) {
    return false
  }
}

export const deleteEvent = async payload => {
  try {
    await API.delete('/private/event', { params: payload, })
    return true
  } catch (error) {
    return false
  }
}

// update moult status
export const updateMoult = async payload => {
  try {
    await API.put('/private/animal/moult', payload)
    return true
  } catch (error) {
    return false
  }
}

// schedule
export const requestSchedule = async payload => {
  try {
    const request = await API.get('/private/schedule', { params: payload, })
    return request.data
  } catch (error) {
    return false
  }
}

export const createSchedule = async payload => {
  try {
    const request = await API.post('/private/schedule', payload)
    return request.data
  } catch (error) {
    return false
  }
}

export const updateSchedule = async payload => {
  try {
    const req = await API.put('/private/schedule', payload)
    return req.data
  } catch (error) {
    return false
  }
}

export const deleteSchedule = async payload => {
  try {
    await API.delete('/private/schedule', { params: payload, })
    return true
  } catch (error) {
    return false
  }
}
