import React, { useState, } from 'react'
import { Button, Card, CardActions, CardContent, Input, makeStyles, Typography, } from '@material-ui/core'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  cardActionInput: {
    flexDirection: 'column',
    display: 'flex',
  },
}))

export default function Product (props) {
  const { t, } = useTranslation()
  const classes = styles()

  const { deleteStock, updateStock, stock, } = props
  const [ name, setName, ] = useState(stock.name)
  const [ number, setNumber, ] = useState(stock.number)
  const [ isUpdate, setIsUpdate, ] = useState(false)

  const update = () => {
    updateStock({ id: stock.id, name, number, })
    setIsUpdate(false)
  }

  return (
    <Card
      item
      xs={12}
      sm={6}
      md={4}
      xl={3}
      className={classes.root}
    >
      {isUpdate ? (
        <div>
          <CardContent className={classes.cardActionInput}>
            <Input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              name={'name'}
              placeholder={'name'}
            />
            <Input
              type="number"
              value={number}
              onChange={e => setNumber(e.target.value)}
              name={'number'}
              placeholder={0}
            />
          </CardContent>
          <CardActions>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={update}
            >
              {t('stock.action.save')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => setIsUpdate(!isUpdate)}
            >
              {t('stock.action.cancel')}
            </Button>
          </CardActions>
        </div>
      ) : (
        <div>
          <CardContent>
            <Typography>{stock.name}</Typography>
            <Typography>{stock.number}</Typography>
          </CardContent>
          <CardActions>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => setIsUpdate(!isUpdate)}
            >
              {t('stock.action.update')}
            </Button>
            <Button
              variant={'contained'}
              color={'secondary'}
              onClick={() => deleteStock({ id: stock.id, })}
            >
              {t('stock.action.delete')}
            </Button>
          </CardActions>
        </div>
      )}
    </Card>
  )
}
