import {
  CREATE_STOCK_FAILED,
  CREATE_STOCK_REQUEST,
  CREATE_STOCK_SUCCESS,
  DELETE_STOCK_FAILED,
  DELETE_STOCK_REQUEST,
  DELETE_STOCK_SUCCESS,
  GET_STOCK_FAILED,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  RESET_STOCK,
  UPDATE_STOCK_FAILED,
  UPDATE_STOCK_REQUEST,
  UPDATE_STOCK_SUCCESS,
} from './constants'

const initialState = {
  error: false,
  loading: false,
  stock: [],
}

export default (state = initialState, { type, payload, }) => {
  switch (type) {
    case GET_STOCK_SUCCESS:
      return {
        error: false,
        loading: false,
        stock: payload,
      }
    case GET_STOCK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case GET_STOCK_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case CREATE_STOCK_SUCCESS:
      return {
        error: false,
        loading: false,
        stock: [ ...state.stock, payload, ],
      }
    case CREATE_STOCK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case CREATE_STOCK_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case UPDATE_STOCK_SUCCESS:
      return {
        error: false,
        loading: false,
        stock: [ ...state.stock.filter(e => e.id !== payload.id), payload, ],
      }
    case UPDATE_STOCK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case UPDATE_STOCK_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case DELETE_STOCK_SUCCESS:
      return {
        error: false,
        loading: false,
        stock: [ ...state.stock.filter(e => e.id !== payload.id), ],
      }
    case DELETE_STOCK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case DELETE_STOCK_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      }

    case RESET_STOCK:
      return initialState

    default:
      return { ...state, }
  }
}
