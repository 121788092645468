import { formatDistanceToNow, } from 'date-fns'
import React from 'react'
import { fr, } from 'date-fns/locale'
import { Card, CardActionArea, CardContent, CardMedia, Grid, makeStyles, Typography, } from '@material-ui/core'

import AnimalForm from '../Animal/AnimalForm'

import { navigate, } from '@reach/router'
import { useSelector, } from 'react-redux'
import { useTranslation, } from 'react-i18next'

const styles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    display: 'flex',
    padding: 10,
  },
}))

export default function Index () {
  const { t, } = useTranslation()
  const classes = styles()

  const { animals, } = useSelector(state => state.animal)

  return (
    <Grid container spacing={4} className={classes.root}>
      {animals.length
        ? animals.map(animal => {
          return (
            <Grid key={`home_animal_${animal.id}`} xs={12} sm={4} lg={3} item>
              <Card key={`home_animal_card_${animal.id}`}>
                <CardActionArea
                  onClick={() => navigate(`/animal/${animal.id}`)}
                >
                  <CardMedia
                    style={{ height: 140, }}
                    image={animal.picture}
                    title={animal.name}
                  />
                  <CardContent>
                    <Typography>
                      {t('home.animal.name', { name: animal.name, })}
                    </Typography>
                    {animal.mealRecurency ? (
                      <Typography>
                        {t(`home.animal.mealRecurency.nextMeal.${animal.translateKey}`, {
                          dateString: animal.nextMeal,
                        })}
                      </Typography>
                    ) : null}
                    <Typography>
                      {t('home.animal.age', {
                        date: formatDistanceToNow(new Date(animal.birthday), {
                          locale: fr,
                        }),
                      })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })
        : null}
      <Grid xs={12} sm={4} lg={3} item>
        <AnimalForm />
      </Grid>
    </Grid>
  )
}
