import React, { useState, } from 'react'
import { format, formatDistanceToNow, } from 'date-fns'
import { fr, } from 'date-fns/locale'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useTranslation, } from 'react-i18next'
import { useDispatch, } from 'react-redux'
import { UPDATE_MOULT, } from '../../../store/animal/constants'

const styles = makeStyles(theme => ({
  card: {
    overflowY: 'scroll',
  },
  picture: {
    height: 200,
  },
  cardAction: {
    justifyContent: 'center',
  },
  info: {
    whiteSpace: 'pre-line',
  },
}))

export default function Header (props) {
  const { t, } = useTranslation()
  const classes = styles()
  const dispatch = useDispatch()

  const { animal, handleUpdate, } = props

  const [ isMoult, setIsMoult, ] = useState(animal.isMoult || false)

  const handleChangeMoult = () => {
    dispatch({ type: UPDATE_MOULT, payload: { id: animal.id, isMoult: !isMoult, }, })
    setIsMoult(!isMoult)
  }

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.picture}
        image={animal.picture}
        title={animal.name}
      />
      <CardContent>
        <Typography>
          {t('animal.header.name', { name: animal.name, })}
        </Typography>
        <Typography className={classes.info}>
          {t('animal.header.description', { description: animal.description, })}
        </Typography>
        <Typography>
          {t('animal.header.birthday', {
            date: format(new Date(animal.birthday), 'd MMMM yyyy', {
              locale: fr,
            }),
          })}
        </Typography>
        <Typography>
          {t('animal.header.age', {
            date: formatDistanceToNow(new Date(animal.birthday), {
              locale: fr,
            }),
          })}
        </Typography>
        {animal.mealRecurency ? (
          <Typography>
            {t(`animal.header.mealRecurency.nextMeal.${animal.translateKey}`, {
              dateString: animal.nextMeal,
            })}
          </Typography>
        ) : null}
        {animal.mealRecurency ? (
          <Typography>
            {t(
              `animal.header.mealRecurency.${
                animal.mealRecurency.split('/')[1]
              }`,
              {
                number: animal.mealRecurency.split('/')[0],
              }
            )}
          </Typography>
        ) : null}
      </CardContent>
      <CardActions className={classes.cardAction}>
        <Button onClick={handleUpdate} variant={'contained'} color={'primary'}>
          {t('animal.header.action.update')}
        </Button>
        {animal.type === 'SNAKE' ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={isMoult}
                onChange={handleChangeMoult}
                name={'isMoult'}
                color={'primary'}
              />
            }
            label={t('animal.header.action.updateMoult')}
          />
        ) : null}
      </CardActions>
    </Card>
  )
}
